<template>
  <div class="m-0 p-0">
    <div class="form-group" :class="{'row': isInline}" v-if="!hasInputDesign">
      <label :for="name" class="col-form-label" :class="{
      'col-lg-4 col-md-4 col-sm-12': isInline,
      'col-form-label-sm': size.toString() === 'sm',
      'col-form-label-lg': size.toString() === 'lg',
    }">{{ title }}<span class="text-danger" v-if="required">*</span>
        <info-tooltip
            :helper-text="helperText"
            v-if="helperText"
        ></info-tooltip>
      </label>
      <input  type="file" :multiple="multiple" :name="name" :disabled="false" :ref="name" @change="onChangeFile"
              :class="inputClasses"
              :accept="accept" class="input-file form-control">
      <div class="offset-lg-4 offset-md-4 offset-sm-0 col-lg-8 col-md-8 col-sm-12 px-0">
      <span class="form-text text-muted"
            :class="{'offset-lg-3 offset-md-3 col-lg-9 col-md-9 col-sm-12': isInline}">{{ hint }}</span>
        <p class="text-danger pt-1 px-3 block" :class="{'offset-lg-3 offset-md-3 col-lg-9 col-md-9 col-sm-12': isInline}"
           v-if="error">{{ error }}</p>
      </div>
    </div>
    <div class="" v-if="hasInputDesign">
      <slot name="inputDesign" @click="openFileInput"></slot>
      <input type="file" :ref="name" :multiple="multiple"  :id="name" class="d-none" @change="onChangeFile" :accept="accept">
    </div>
  </div>
</template>
<script>
import InfoTooltip from "@/assets/components/widget/InfoTooltip";
import $ from 'jquery';
import {v4 as uuidv4} from 'uuid'

export default {
  name: "FileInput",
  props: {
    name: {
      default: uuidv4(),
    },
    model: {},
    title: {},
    error: {},
    hint: {},
    multiple: {
      required: false,
      default: false,
    },
    accept: {
      default: "image/*",
    },
    isInline: {
      required: false,
      default:true,
    },
    placeholder: {},
    required: {
      default: true
    },
    helperText: {
      default: null,
      required: false,
      type: String,
    },
    size: {
      default: 'sm',
      validator: function validator(l) {
        return ['xs', 'sm', 'md', 'lg'].indexOf(l) > -1;
      }
    },
    isValid: {
      required: false,
      default: null,
    },
  },
  methods: {
    onChangeFile(){
      if(this.$refs[this.name].files.length > 0 && !this.multiple){
        this.modelProxy = this.$refs[this.name].files[0];
      }else if(this.$refs[this.name].files.length > 0 && this.multiple){
        this.modelProxy = this.$refs[this.name].files;
      }
    },
    openFileInput(){
      $(`#${this.name}`).trigger('click');
    }
  },
  components: {
    InfoTooltip
  },
  computed: {
    inputClasses() {
      let result = {
        'form-control-sm': this.size.toString() === 'sm',
        'form-control-lg': this.size.toString() === 'lg',
        'is-valid': this.isValid == null ? false : this.isValid && this.modelProxy !== null || this.modelProxy === '',
        'is-invalid': this.isValid == null ? false : !this.isValid,
      };
      if (Object(this.inputClass) === this.inputClass) {
        result = {...result, ...this.inputClass};
      } else if (Array.isArray(this.inputClass)) {
        this.inputClass.forEach(value => {
          result[value] = true
        })
      } else if (this.inputClass !== null) {
        result[this.inputClass] = true;
      }
      return result;
    },

    modelProxy: {
      get() {
        return this.model
      },
      set(value) {
        this.$emit('update:model', value)
        if(value.length === undefined || isNaN(Number(value.length)))
        {
          this.$emit('fileChange', {
            "id": "preview-" + this.name,
            "file": value,
            "blob": URL.createObjectURL(value),
            "name": value['name'],
            "type": value['type']
          });
        }else{
          let returnValue = [];
          for(let i = 0; i < value.length; i++){
            returnValue.push({
              "file": value[i],
              "blob": URL.createObjectURL(value[i]),
              "name": value[i]['name'],
              "type": value[i]['type']
            })
          }
          this.$emit('fileChange', {
            files: returnValue
          });
        }
      }
    },

    hasInputDesign() {
      return !!this.$slots.inputDesign
    },
  },
}
</script>
<style scoped>
</style>