<template>
  <div class="row mt-10">
    <div class="col-12">
      <KTCard ref="preview" v-bind:example="true">
        <template v-slot:body>
          <div>
            <table id="my-table" aria-busy="false" aria-colcount="13"
                   class="table b-table table-borderless b-table-no-border-collapse table-''" role="table">
              <thead class="thead-none" role="rowgroup">
              <tr></tr>
              <tr class="" role="row">
                <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                  <div><span>{{ $t('packing_list.po_number') }} <i class="text-danger">*</i></span></div>
                </th>

                <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                  <div><span>{{ $t('packing_list.color_name') }} <i class="text-danger">*</i></span></div>
                </th>

                <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                  <div><span>{{ $t('packing_list.color_number') }} <i class="text-danger">*</i></span></div>
                </th>

                <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                  <div><span>{{ $t('packing_list.body_size') }} <i class="text-danger">*</i></span></div>
                </th>

                <th class="text-center border border-top-0 border-right-0  font-size-lg " style="width: 95px;">
                  <div><span>{{ $t('packing_list.request_quantity') }} <i class="text-danger">*</i></span></div>
                </th>

                <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                  <div><span>{{ $t('packing_list.d_any') }} <i class="text-danger">*</i></span></div>
                </th>

                <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                  <div><span>{{ $t('packing_list.box_quantity') }} <i class="text-danger">*</i></span></div>
                </th>

                <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                  <div><span>{{ $t('packing_list.broken_parcel') }} <i class="text-danger">*</i></span></div>
                </th>

                <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                  <div><span>{{ $t('packing_list.in_package') }} <i class="text-danger">*</i></span></div>
                </th>

                <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                  <div><span>{{ $t('packing_list.box_weight') }} <i class="text-danger">*</i></span></div>
                </th>

                <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                  <div><span>{{ $t('packing_list.total') }} <i class="text-danger">*</i></span></div>
                </th>

                <th class="text-center border border-top-0 border-right-0  font-size-lg ">
                  <div><span>{{ $t('packing_list.document_or_image_upload') }} <i class="text-danger">*</i></span></div>
                </th>
              </tr>
              </thead>
              <tbody role="rowgroup">
              <template v-for="(item, index) in orderInformations">
                <tr class="b-table-empty-row" role="row">
                  <td class="" role="cell">
                    <span class="flex-grow-1 text-sm py-1 px-1 text-center w-100" type="text"> {{
                        item.po_number
                      }}</span>
                  </td>
                  <td class="" role="cell">
                    <span class="flex-grow-1 text-sm py-1 px-1 text-center w-100" type="text"> {{
                        item.color_name
                      }}</span>
                  </td>
                  <td class="" role="cell">
                    <span class="flex-grow-1 text-sm py-1 px-1 text-center w-100" type="text"> {{
                        item.color_number
                      }}</span>
                  </td>
                  <td class="" role="cell">
                    <span class="flex-grow-1 text-sm py-1 px-1 text-center w-100" type="text"> {{
                        item.body_size
                      }}</span>
                  </td>
                  <td class="" role="cell">
                    <span class="flex-grow-1 text-sm py-1 px-1 text-center w-100" type="text"> {{ item.amount }}</span>
                  </td>
                  <td class="" role="cell">
                    <span class="flex-grow-1 text-sm py-1 px-1 text-center w-100" type="text">
                       {{ item.d_any }}
                     </span>
                  </td>
                  <td class="" role="cell">
                    <span class="flex-grow-1 text-sm py-1 px-1 text-center w-100" type="text">
                       {{ item.box_quantity }}
                     </span>
                  </td>
                  <td class="" role="cell">
                     <span class="flex-grow-1 text-sm py-1 px-1 text-center w-100" type="text">
                       {{ item.broken_parcel }}
                     </span>
                  </td>
                  <td class="" role="cell">
                     <span class="flex-grow-1 text-sm py-1 px-1 text-center w-100" type="text">
                       {{ item.in_package }}
                     </span>
                  </td>
                  <td class="" role="cell">
                     <span class="flex-grow-1 text-sm py-1 px-1 text-center w-100" type="text">
                       {{ item.box_weight }}
                     </span>
                  </td>
                  <td class="" role="cell">
                     <span class="flex-grow-1 text-sm py-1 px-1 text-center w-100" type="text">
                       {{ item.box_quantity * item.in_package }}
                     </span>
                  </td>
                  <td class="text-center" role="cell">
                    <img v-show="item.image"
                         :id="`preview-content-image-${index}`"
                         alt=""
                         class="w-50px h-50px object-cover border-15px navy-blue-border-1px cursor-pointer"
                    >
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>

          <div class="d-flex justify-content-center mt-5">
            <button-with-icon
                :icon-name="null"
                :text="$t('general.back')"
                class="mr-3 danger-border-1px"
                size="lg"
                @onClick="cancelClick"
            ></button-with-icon>
          </div>
        </template>
      </KTCard>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters, mapMutations} from 'vuex'
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import FileInput from "@/assets/components/inputs/FileInput";

import {
  CURRENT_ITEM,
  ERROR,
  FILTER,
  GET_ITEM_DETAIL_BY_ID,
  GET_ITEMS,
  LOADING,
  SET_ERROR,
  SET_FILTER,
  SET_ITEMS,
  SET_LOADING,
  SUCCESS
} from "@/core/services/store/packing-list/packing_list_production.module"

import {LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import KTCard from "@/view/content/Card.vue";
import $ from "jquery";
import * as _ from "lodash";
import draggable from 'vuedraggable'

export default {
  name: "PackingListDetail",
  components: {
    CustomMultiSelect,
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    ButtonWithIcon,
    DateFilter,
    KTCard,
    FileInput,
    draggable
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
      success: SUCCESS,
      packing: CURRENT_ITEM,
      filter: FILTER
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      }
    }
  },
  data() {
    return {
      fieldsToViewEdit: [],
      editable: true,
      isDragging: false,
      delayedDragging: false,
      modelTableItem: [
        {
          user_id: null,
          model_name: null,
          table_fields: {},
        }
      ],
      orderInformations: [],
      colorInformations: [],
      orderUrl: 'api/following-production/packing-list/orders',
      search: null,
      status_id: null,
      page: 1,
      per_page: 10,
      total_quantity: 0,
      total_box_quantity: 0,
      form: {
        id: null,
        order_id: null,
        order_size_and_color_id: [],
      },
      items: [],
      itemId: this.$route.params.id,
      packingListStatuses: [],
    };
  },
  methods: {
    ...mapMutations({
      setItems: SET_ITEMS,
      setError: SET_ERROR,
      setFilter: SET_FILTER,
    }),
    cancelClick() {
      this.$router.push({name: 'following_export.index'});
    },
    createNewItem() {
      this.orderInformations = [];
      this.isCreating = true
      this.form.order_size_and_color_id = [];
      this.form.order_id = null;

      if (this.itemId) {
        this.itemId = null;
        setTimeout(this.onHitBottom, 500);
      }
    },
    onChangeSearchFilter(key, value) {
      this.$set(this.filters, key, value);
    },
    openCreateNewESCPage() {
      this.$router.push({name: 'time-sheet.create', params: {type: 1}, query: this.$route.query})
    },
    orderList() {
      this.modelTableItem[0].table_fields = this.modelTableItem[0].table_fields.sort((one, two) => {
        return one.sort - two.sort;
      });
    },
    onMove({relatedContext, draggedContext}) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
          (!relatedElement || !relatedElement.status) && !draggedElement.status
      );
    },
    openPopToSettingTable() {
      this.$modal.show('open_to_settings_table')
    },
    closeModal() {
      this.$modal.hide('open_to_settings_table');
    },
    getPackingListItem() {
      let self = this;
      let parameterPackingID = self.itemId
      if (parameterPackingID) {
        let payload = {
          url: 'api/following-export/packing-list/' + parameterPackingID,
          id: this.id,
          redirectRouteOnNotFound: null
        }
        this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, payload).then(response => {
          if (response.status) {
            let items = response.data;
            let results = [];
            let orderSizeAndColorIds = [];

            items.forEach(orderItem => {
              orderItem.data.forEach((subItem, index) => {
                orderSizeAndColorIds.push(subItem.order_size_and_color_id.toString());

                results.push({
                  id: orderItem.id,
                  order_number: orderItem.orderable ? orderItem.orderable.order_number : "",
                  po_number: orderItem.orderable ? orderItem.orderable.po_number : "",
                  order_id: orderItem.orderable ? orderItem.orderable.id : "",
                  order_size_and_color_id: Number(subItem.order_size_and_color_id),
                  d_any: subItem.d_any,
                  color_number: subItem.color_number,
                  color_name: subItem.color_name,
                  body_size: subItem.body_size,
                  box_quantity: subItem.box_quantity,
                  in_package: subItem.in_package,
                  broken_parcel: subItem.broken_parcel,
                  amount: subItem.amount,
                  total: subItem.in_package * subItem.box_quantity,
                  box_weight: subItem.box_weight,
                  image: subItem.image,
                  image_link: orderItem.image_link[index],
                  image_link_column: true,
                  status: subItem.status,
                  createdAt: (subItem.created_at) ? moment(subItem.created_at, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT) : '',
                  actions: {get: true}
                })
              })
            })


            self.orderInformations = results;
            self.form.order_size_and_color_id = _.uniq(orderSizeAndColorIds);
            setTimeout(() => {
              self.orderInformations.forEach((item, index) => {
                $(`#preview-content-image-${index}`).attr('src', item.image_link);
              })
            }, 100);
          }
        })
      }
    },
    getPackingListStatus() {
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/statuses',
        filters: {
          type_id: 5
        },
        acceptPromise: true,
        showLoading: false,
      }).then(result => {
        if (result.status) {
          self.packingListStatuses = self.convertArrayToObjectByKey(result.data, 'id', 'translations.0.name')
        }
      });
    }
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("general.dashboard"), route: "dashboard"},
      {title: this.$t("menu.management.packing_list")}
    ]);

    if (!this.isUserGranted('Export', ['viewAny'])) {
      return false;
    }

    this.getPackingListStatus();

    if (this.itemId) {
      setTimeout(this.getPackingListItem, 500);
    }
  }
}
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}
</style>
